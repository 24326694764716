import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import * as d3 from "d3";
import CachedIcon from '@mui/icons-material/Cached';
import ColorPicker from "../utility/ColorPicker";
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import '../../styles/Dashboard.css';
import { daysBetween } from '../utility/Dates';

export const FrequencyGraphWrapper = (props) => {
    const [updateGraph, setUpdateGraph] = useState(false);
    const graph = useMemo(() => <FrequencyGraph {...props} />, [props.tasks, updateGraph, props.rangeEnd]);
    const rotateCallback = useCallback((e) => {
      e.target.classList.toggle("rotated");
      setUpdateGraph(!updateGraph);
    }, [updateGraph, setUpdateGraph])

    return <>
      <div style={{ width: "100%", textAlign: "right"}}>
        <Tooltip title="Refresh tasks">
          <IconButton onClick={rotateCallback}>
            <CachedIcon className='refreshButton' sx={{color: "white"}}/>
          </IconButton>
        </Tooltip>
      </div>
      {graph}
    </>;
}

export const FrequencyGraph = (props) => {
    const { tasks, rangeEnd } = props;
    const svg = useRef(null);
    // Chart visual
    const width = 1000;
    const height = 500;
    const marginTop = 20;
    const marginRight = 30;
    const marginBottom = 30;
    const marginLeft = 100;

    // Chart data
    const data = [];

    // const rangeEnd = new Date();
    rangeEnd.setDate(rangeEnd.getDate() + 1);
    rangeEnd.setHours(0);
    rangeEnd.setMinutes(0);
    rangeEnd.setSeconds(0);
    rangeEnd.setMilliseconds(0);
    const rangeStart = new Date(rangeEnd);
    rangeStart.setDate(rangeStart.getDate() - 35);

    for (const task of tasks) {
        data.push(task.history.filter((event) => event.date >= rangeStart));
    }

    const dayExtent = [rangeStart, rangeEnd];
    
    const weekInterval = d3.timeInterval(
      (date) => {
        const daysSince = daysBetween(date, rangeStart);
        const newDate = new Date(rangeStart);
        newDate.setDate(newDate.getDate() + Math.floor(daysSince / 7) * 7)
        return newDate;
      },
      (date, step) => date.setUTCDate(date.getUTCDate() + step * 7)
    );

    const dayScale = d3.scaleTime()
        .domain(dayExtent);

    const bin = d3.bin()
        .value(d => d.date) // Use the date as the value
        .domain(dayScale.domain())  // Set the domain for the bins (min and max date)
        .thresholds(weekInterval.range(rangeStart, rangeEnd));
    
    const freqData = data
        .map((task) => bin(task))
        .map((distribution) => 
            distribution.map((bin) => ({ date: bin.x0, count: bin.length }))
        );

    const xScale = d3.scaleUtc()
        .domain(dayExtent)
        .range([marginLeft, width - marginRight]);
      
    const yScale = d3.scaleLinear()
        .domain([0, 7])
        .range([height - marginBottom, marginTop]);

    const lineGenerator = d3.line()
        .x(d => xScale(d.date))
        .y(d => yScale(d.count));
      
    const byTitle = new Map();
    freqData.forEach((series, i) => {
        byTitle.set(tasks[i].id, series)
    });
    
    // lines
    useEffect(() => {
      d3.selectAll("#svg > *").remove();

      d3.select("#svg").append('g')
          .attr("transform", `translate(0,${height - marginBottom})`)
          .call(d3.axisBottom(xScale)
                  .tickValues([rangeStart].concat(weekInterval.range(rangeStart, rangeEnd)))
                  .tickFormat(d => d.toDateString()));
        
      d3.select("#svg").append('g')
          .attr("transform", `translate(${marginLeft},0)`)
          .call(d3.axisLeft(yScale)
            .ticks(7) )
          .call(g => g.selectAll(".tick line").clone()
            .attr("x2", width - marginRight)
            .attr("stroke-opacity", 0.4));
      
      const serie = d3.select("#svg").append("g")
        .selectAll()
        .data(byTitle)
        .join("g")
        .attr("id", d => `path${d[0].substr(0, d[0].length - 1)}`);

      var color = d3.scaleOrdinal()
        .domain(tasks.map((task) => task.id))
        .range(tasks.map((task) => ColorPicker.getColor(task)));

      // Draw the lines.
      serie.append("path")
          .attr("fill", "none")
          .attr("stroke", d => color(d[0]))
          .attr("stroke-width", 1.5)
          .attr("d", d => lineGenerator(d[1]));
      
      serie.append("g")
          .attr("stroke", d => color(d[0]))
          .attr("fill", d => color(d[0]))
          .attr("stroke-width", 2.5)
        .selectAll("circle")
        .data(d => d[1])
        .join("circle")
          .attr("cx", d => xScale(d.date))
          .attr("cy", d => yScale(d.count))
          .attr("r", 2.5);

      const legend = d3.select("#svg").selectAll(".legend")
        .data(byTitle)
        .enter().append("g")
        .attr("class", "legend")
        .attr("transform", (d, i) => `translate(0,${i * 20})`);
      
      legend.append("rect")
        .attr("x", width - 18)
        .attr("width", 18)
        .attr("height", 18)
        .style("fill", d => color(d[0]))
        .on("click", d => { 
            const pathId = d.target.__data__[0];
            const pathEl = d3.select(`#path${pathId.substr(0, pathId.length - 1)}`);
            const isHidden = pathEl._groups[0][0].style.visibility == 'hidden';
            if (isHidden) {
                pathEl.style('visibility', 'visible');
                d.target.parentNode.style.opacity = 1;
            }
            else {
                pathEl.style('visibility', 'hidden');
                d.target.parentNode.style.opacity = 0.25;
            }
        });
      
      legend.append("text")
        .attr("x", width - 24)
        .attr("y", 9)
        .attr("dy", ".35em")
        .attr("fill", "white")
        .style("text-anchor", "end")
        .text(d => tasks.find((task) => task.id === d[0])?.title);
    }, [rangeEnd]);

    return  <svg id="svg" width={width} height={height}> </svg>
}