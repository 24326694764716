import React, { ReactNode, useState, useCallback, useEffect } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import IconButton from '@mui/material/IconButton';

import {CalendarMonth, CalendarWeek} from "./Month";
import {Task} from "../Task";

import '../../styles/Calendar.css';
import '../../styles/Dashboard.css';
import { CalendarOptions, CalendarRange } from './CalendarOptions';
import { dayOfWeekString, daysOfWeek, monthDays } from '../utility/Dates';

interface ICalendarControlProps {
    tasks: Task[],
    year: number,
    month: number,
    weekStartDate: number,
    calendarRange: CalendarRange,
}

export const CalendarControl: React.FC<ICalendarControlProps> = (props: ICalendarControlProps) => {
    const { tasks, year, month, weekStartDate, calendarRange } = props;

    return <div>
        <div className="calendarMonth">
            {
                Array.from({ length: 7}, (_, i) => i).map((day: number) =>
                    <div className="calendarMonthWeekdayLabel" key={day}>
                        { daysOfWeek[day] }
                    </div>)
            }
        </div>
        {
            calendarRange === CalendarRange.Week ? 
            <CalendarWeek 
                month={month} year={year} tasks={tasks}
                offset={weekStartDate <= 0 ? Math.abs(weekStartDate) + 1 : 0}
                startDate={weekStartDate}/> : 
            <CalendarMonth month={month} year={year} tasks={tasks} />
        }
    </div>;
}
