import React, { useEffect, useState, useCallback, useRef, RefObject } from 'react';

import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Card, CardContent, Icon, IconButton, ListItemText, TextField, Typography } from '@mui/material';

import "../../styles/Tasks.css";
import "../../styles/App.css";
import IconLibrary from '../utility/IconLibrary';
import { Color, ColorPicker, ColorPalette } from 'material-ui-color';
import { saveTaskTag } from '../Loader/Persist';
import { useCookies } from 'react-cookie';

export const TagList: ITag[] = [
];

export interface ITag {
    id?: number,
    name: string,
    color: string,
    icon: React.ReactElement,
    iconKey: string
}

interface ITagEditable extends ITag{
    incomplete?: boolean
}

interface ITagSelectorProps {
    defaultValue?: ITag,
    ref: RefObject<HTMLInputElement>,
}

export const TagSelector: React.FC<ITagSelectorProps> = (props) => {
    const { defaultValue, ref } = props;
    
    /* Modal settings */
    const [open, setOpen] = useState(false);
    const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setOpen(true);
    }
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    
    return <>
        <div className="taskEditTagSelector">
            <FormControl sx={{width: "50%"}}>
                <InputLabel>Category</InputLabel>
                <Select
                    defaultValue={defaultValue?.name}
                    label="Category"
                    inputRef={ref}
                >
                    { TagList.map((tag) => (
                    <MenuItem key={tag.name} value={tag.name}>
                        <ListItemText primary={tag.name}/>
                    </MenuItem>
                    )) }
                </Select>
            </FormControl>
            <div onClick={handleOpen}>
            <IconButton>
               <EditIcon/>
            </IconButton>
            </div>
        </div>
        <Modal
            open={open}
            onClose={handleClose}
          >
            <Box className="modalBox" sx={{
              bgcolor: 'background.paper',
              width: 800,
              boxShadow: 24,
            }}>
                <CardContent>
                    <TagCardList />
                </CardContent>
            </Box>
      </Modal>
    </>;
}


interface ITagCardListProps {
}

export const TagCardList: React.FC<ITagCardListProps> = (props) => {
    const [tagList, setTagList] = useState<ITagEditable[]>(TagList);
    
    const addTag = useCallback(() => {
        tagList.push({
            name: "",
            color: "#000000",
            icon: <TheaterComedyIcon />,
            iconKey: "TheaterComedy",
            incomplete: true,
        });
        setTagList([...tagList]);
    }, [tagList, setTagList]); 

    return <div>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <IconButton onClick={addTag} disabled={tagList.find((tag: ITagEditable) => tag.incomplete) !== undefined }>
                    <AddIcon />
                </IconButton>
            </CardContent>
        </Card>
        {tagList.map((tag: ITag) =>
            <TagCard key={tag.name} tag={tag} tagList={tagList} setTagList={setTagList}/>)}
    </div>;
}


interface ITagCardProps {
    tag: ITagEditable,
    tagList: ITagEditable[],
    setTagList: (tagList: ITagEditable[]) => void
}

export const TagCard: React.FC<ITagCardProps> = (props) => {
    const { tag, tagList, setTagList } = props;
    const [cookies, setCookie] = useCookies(["user"]);
    const [editMode, setEditMode] = useState(tag.incomplete || false);

    const toggleEdit = useCallback(() => {
        setEditMode(!editMode);
    }, [editMode, setEditMode]);

    const saveTag = useCallback((updatedTag: ITagEditable) => {
        updatedTag.incomplete = !editMode;
        const index = tagList.indexOf(tag);
        if (index >= 0) {
            Object.assign(tagList[index], updatedTag);
        }
        else {
            tagList.push(tag);
            TagList.push(tag);
        }
        setTagList([...tagList]);
        setEditMode(false);

        saveTaskTag(cookies.user, tag);
    }, [tag, tagList, setTagList, editMode, setEditMode]);

    return <Card sx={{ minWidth: 275 }}>
        <CardContent className="tagCard" sx={{ borderLeftColor: tag.color }}>
            {
                editMode ? 
                <TagEdit {...props} onSave={saveTag} onCancel={toggleEdit} />
                :
                <>
                    <div className='tagCardIcon'>
                        {tag.icon}
                    </div>
                    <Typography className="tagCardName" variant='h5'>
                        {tag.name}
                    </Typography>
                    <IconButton className="tagEditButton" onClick={toggleEdit}>
                        <EditIcon />
                    </IconButton>
                </>
            }
        </CardContent>
    </Card>;
}

interface ITagEditCardProps extends ITagCardProps{
    onSave: (tag: ITagEditable) => void,
    onCancel: () => void
}

const TagEdit: React.FC<ITagEditCardProps> = (props) => {
    const { tag, onSave, onCancel } = props;

    const titleEditRef = useRef<HTMLInputElement>(null);
    const [changed, setChanged] = useState({...tag});

    const updateTagName = useCallback(() => {
        changed.name = titleEditRef.current?.value || "";
        setChanged({...changed});
    }, [titleEditRef, setChanged, changed]);

    const updateChanges = useCallback(() => {
        setChanged({...changed})
    }, [tag, changed, setChanged]);

    return <div className='tagEditCard'>
            <div>
                <TextField className="tagNameField" 
                    type="text" 
                    inputRef={titleEditRef}
                    value={changed.name}
                    onChange={updateTagName}
                    variant="standard" 
                    label="Tag" 
                    autoFocus/>
                <IconControl {...props} tag={changed} updateTag={updateChanges} />
                <ColorControl {...props} tag={changed} updateTag={updateChanges} />
            </div>
            <div>
                <div className='tagEditCardButton'>
                    <IconButton onClick={() => onSave(changed) }>
                        <SaveIcon />
                    </IconButton>
                </div>
                <div className='tagEditCardButton'>
                    <IconButton onClick={onCancel}>
                        <CancelIcon />
                    </IconButton>
                </div>
            </div>
        </div>;
}

interface ITagEditComponentProps extends ITagCardProps{
    updateTag: () => void
}

const IconControl: React.FC<ITagEditComponentProps> = (props) => {
    const { tag, updateTag, tagList } = props;
    
    const [open, setOpen] = useState(false);
    const launcherRef = useRef<HTMLButtonElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const updateIcon = (iconKey: string) => {
        tag.iconKey = iconKey;
        tag.icon = IconLibrary[iconKey];
        updateTag();
    }

    return <>
        <Button onClick={handleOpen} variant="outlined" style={{ color: tag.color }} 
                startIcon={tag.icon} ref={launcherRef}>
            Icon
        </Button>
        <Modal
            open={open}
            onClose={handleClose}
          >
            <Box sx={{
              bgcolor: 'background.paper',
              position: "absolute",
              top: (launcherRef.current?.getBoundingClientRect().top || 0) + (launcherRef.current?.clientHeight || 0),
              left: launcherRef.current?.getBoundingClientRect().left,
              width: 500,
              boxShadow: 24,
            }}>
                <div className="tagEditIconModal">
                {
                    Object.keys(IconLibrary).map((iconKey: string, index: number) => 
                        <IconButton key={index} onClick={() => updateIcon(iconKey)}
                                disabled={tagList.find((tag: ITag) => tag.iconKey === iconKey) !== undefined} >
                            {IconLibrary[iconKey]}
                        </IconButton>
                    )
                }
                </div>
            </Box>
      </Modal>
    </>;
}

const ColorControl: React.FC<ITagEditComponentProps> = (props) => {
    const { tag, updateTag, tagList, setTagList } = props;
    
    const [open, setOpen] = useState(false);
    const launcherRef = useRef<HTMLButtonElement>(null);
    const [color, setColor] = useState(tag.color);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const updateColor = useCallback((newColor: Color) => {
        setTagList([...tagList])
        tag.color = "#" + newColor.hex;
        setColor(tag.color);
        updateTag();
    }, [color, setColor, tagList, setTagList]);

return <>
        <Button onClick={handleOpen} variant="contained" sx={{ backgroundColor: color }}
                ref={launcherRef}>
            Color
        </Button>
        <Modal
            open={open}
            onClose={handleClose}
          >
            <Box sx={{
              bgcolor: 'background.paper',
              position: "absolute",
              top: (launcherRef.current?.getBoundingClientRect().top || 0) + (launcherRef.current?.clientHeight || 0),
              left: launcherRef.current?.getBoundingClientRect().left,
              width: 220,
              boxShadow: 24,
            }}>
                <div className="tagEditIconModal">
                    <ColorPicker value={color} onChange={updateColor}/>
                </div>
            </Box>
      </Modal>
    </>;
}