import React, { useCallback, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { ISubscriber, ISubscriberCallback } from "./Subscription";

import { CalendarControl } from "./Calendar/CalendarControl";
import { Task } from "./Task";
import { TaskEvent, TaskEventEditor } from "./TaskEvent";

import ColorPicker from "./utility/ColorPicker";
import { compareDates, dayOfWeekString, daysOfWeek } from "./utility/Dates";

import "../styles/App.css";
import '../styles/Dashboard.css';
import { FrequencyGraphWrapper } from './Graph/FrequencyGraph';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { CalendarOptions, CalendarRange } from './Calendar/CalendarOptions';
import IconButton from '@mui/material/IconButton';

/**
 *
 **/

export class TaskDashboardManager implements ISubscriber {
    subscriptions: Task[];
    subscriptionCallbacks: Map<string, ISubscriberCallback>;

    constructor(tasks: Task[] = []) {
        this.subscriptions = tasks;
        this.subscriptionCallbacks = new Map();
    }

    /**
    *       Accessors / mutators
    **/
        get tasks(): Task[] { return this.subscriptions; }
        private set tasks(tasks: Task[]) { this.subscriptions = tasks; }

    /**
     *      Subscriptions
     **/

        // Add an event source to listen to
        private _triggerCallbacks() {
            Array.from(this.subscriptionCallbacks.values()).forEach(
                (callback: ISubscriberCallback) => callback(null)
            );
        }

        public subscribeToTask(task: Task) {
            Array.from(this.subscriptionCallbacks.keys()).forEach((key: string) => {
                const callback = this.subscriptionCallbacks.get(key)!;
                task.subscribe(callback, key);
            });
            this.tasks.push(task);
            this._triggerCallbacks();
        }

        public unsubscribeFromTask(task: Task) {
            const index = this.tasks.indexOf(task, 0);
            if (index >= 0) {
                this.tasks.splice(index, 1);
            }
            this._triggerCallbacks();
        }

        public addSubscriptionCallback(callback: ISubscriberCallback, key: string): void {
            this.tasks.forEach((task: Task) => {
                task.subscribe(callback, key);
            });

            this.subscriptionCallbacks.set(key, callback);
        }

        public removeSubscriptionCallback(key: string): void {
            this.tasks.forEach((task: Task) => {
                task.unsubscribe(key);
            })
            this.subscriptionCallbacks.delete(key);
        }
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box >
                {children}
            </Box>
        )}
        </div>
    );
}

interface ITaskDashboardProps {
    manager: TaskDashboardManager,
    activeTasks: Task[]
}

export const TaskDashboard: React.FC<ITaskDashboardProps> = (props: ITaskDashboardProps) => {
    const { manager, activeTasks } = props;
    const [tabIndex, setTabIndex] = React.useState(0);
    const [calendarRange, setCalendarRange] = useState<CalendarRange>(CalendarRange.Month);
  
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const date = new Date();
    const [year, setYear] = useState(date.getFullYear());
    const [month, setMonth] = useState(date.getMonth());
    
    const tmp = dayOfWeekString(date);
    const [weekStartDate, setWeekStartDate] = useState(date.getDate() - daysOfWeek.indexOf(tmp));

    const nextMonth = useCallback(() => {
        if (month < 12) { setMonth(month + 1); }
        else { setMonth(1); setYear(year + 1); }
        setWeekStartDate(1);
    }, [month, setMonth, year, setYear, setWeekStartDate]);

    const prevMonth = useCallback(() => {
        if (month > 1) { setMonth(month - 1); }
        else { 
            setMonth(12); 
            setYear(year - 1); 
            setWeekStartDate(1);
        }
    }, [month, setMonth, year, setYear, setWeekStartDate]);

    const nextWeek = useCallback(() => {
        const newDate = new Date(year, month, weekStartDate);
        newDate.setDate(weekStartDate + 7)
        setWeekStartDate(newDate.getDate());
        if (newDate.getMonth() !== month) {
            setMonth(newDate.getMonth());
            setYear(newDate.getFullYear());
        }
    }, [month, setMonth, year, setYear, weekStartDate, setWeekStartDate]);

    const prevWeek = useCallback(() => {
        const newDate = new Date(year, month, weekStartDate);
        newDate.setDate(weekStartDate - 7)
        setWeekStartDate(newDate.getDate());
        if (newDate.getMonth() !== month) {
            setMonth(newDate.getMonth());
            setYear(newDate.getFullYear());
        }
    }, [month, setMonth, year, setYear, weekStartDate, setWeekStartDate]);

    return <>
    <Box sx={{ width: '100%' }}>
        <div className='calendarControlToolbar'>
            <div className='calendarControlMonthToggle'>
                <IconButton onClick={calendarRange === CalendarRange.Month ? prevMonth: prevWeek}>
                <NavigateBeforeIcon />
                </IconButton>
                    <div>
                        <div>
                        { (new Date(year, month, 1)).toLocaleDateString(
                            'en-US',
                            { month: 'long', year: 'numeric'}
                        ) }
                        </div>
                        {calendarRange === CalendarRange.Week  &&
                            <div style={{ textAlign: "center", fontSize: "1rem"}}>
                                {   new Date(year, month, weekStartDate).toLocaleDateString(
                                    'en-US',
                                    { month: 'numeric', day: 'numeric'}) } - 
                                {   new Date(year, month, weekStartDate + 7).toLocaleDateString(
                                    'en-US',
                                    { month: 'numeric', day: 'numeric'}) } 
                            </div>
                        }
                    </div>
                <IconButton onClick={calendarRange === CalendarRange.Month ? nextMonth: nextWeek}>
                <NavigateNextIcon />
                </IconButton>
            </div>
            <CalendarOptions calendarRange={calendarRange} setCalendarRange={setCalendarRange}/>
        </div>
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
            <Tab label="Calendar" sx={{color: 'white'}}/>
            <Tab label="Event Log" sx={{color: 'white'}}/>
            <Tab label="Graph" sx={{color: 'white'}}/>
        </Tabs>
            <TabPanel value={tabIndex} index={0} >
                <CalendarControl tasks={activeTasks} year={year} month={month} weekStartDate={weekStartDate} calendarRange={calendarRange} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1} >
                <TaskEventLog manager={manager} tasks={activeTasks} year={year} month={month} weekStartDate={weekStartDate} calendarRange={calendarRange} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2} >
               <FrequencyGraphWrapper tasks={activeTasks} rangeEnd={new Date(year, month + 1, 1)}/>
            </TabPanel>
        </Box>
    </>;
}

interface ITaskEventLogEntryProps {
    taskEvent: TaskEvent,
}

export const TaskEventLogEntry: React.FC<ITaskEventLogEntryProps> = (props: ITaskEventLogEntryProps) => {
    const { taskEvent } = props;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <div className="taskEventLogEntry" onClick={handleOpen}>
                <span className="editButton"> <EditIcon /> </span>
                <span className="eventLogEntryTitle">{ taskEvent.toString() }</span>
                <Typography variant="body1" className="eventLogEntryDesc" sx={{ fontSize: 20 }}>
                    {taskEvent.comment}
                </Typography>
            </div>
            { open && <TaskEventEditor taskEvent={taskEvent} open={open} handleClose={handleClose} /> }
        </>
    );
}

interface ITaskEventLogProps {
    manager: TaskDashboardManager, 
    tasks: Task[],
    year: number,
    month: number,
    weekStartDate: number,
    calendarRange: CalendarRange,
}

const TaskEventLog: React.FC<ITaskEventLogProps> = (props) => {
    const { tasks, year, month, weekStartDate, calendarRange } = props;

    const rangeStartDate = new Date(year, month, 1);
    const rangeEndDate: Date = new Date(rangeStartDate);
    if (calendarRange === CalendarRange.Month) {
        rangeEndDate.setMonth(rangeEndDate.getMonth() + 1);
    }
    else {
        rangeEndDate.setDate(rangeEndDate.getDate() + 7);
    }

    return <>
        {tasks.map(
            (task: Task, index: number) =>
                <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ backgroundColor: ColorPicker.getColor(task),
                        color: ColorPicker.getNeutralColor(task) }}
                    >
                    { task.title }
                    </AccordionSummary>
                    <AccordionDetails className="tasklogBody">
                    { task.history.length == 0 && "No data"}
                    { [...task.history].reverse().filter(
                        (event: TaskEvent) => 
                            compareDates(event.date, rangeStartDate) >= 0 && compareDates(event.date, rangeEndDate) < 0
                    ).map(
                        (event: TaskEvent, index: number) =>
                        <div key={index}>
                            <TaskEventLogEntry taskEvent={event}/>
                        </div>
                    )}
                    </AccordionDetails>
                </Accordion>
        )}
    </>;
}
