import React, { useState, useRef } from 'react';

    import AddBoxIcon from '@mui/icons-material/AddBox';
    import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
    import CheckBoxIcon from '@mui/icons-material/CheckBox';
    import HighlightOffIcon from '@mui/icons-material/HighlightOff';
    import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

    import Chip from '@mui/material/Chip';
    import Modal from '@mui/material/Modal';
    import Box from '@mui/material/Box';

import { TaskStatus } from "../Task";
import '../../styles/Tasks.css';

function getStatusIcon(status: TaskStatus) {
    switch (status) {
        case TaskStatus.NotStarted:
            return <AddBoxIcon/>
        case TaskStatus.InProgress:
            return <CheckBoxOutlineBlankIcon/>
        case TaskStatus.Completed:
            return <CheckBoxIcon/>
        case TaskStatus.Abandoned:
            return <HighlightOffIcon/>
        default:
            return <QuestionMarkIcon/>
    }
}

function getStatusColor(status: TaskStatus) {
    switch (status) {
        case TaskStatus.NotStarted:
            return "#B0C0BC"
        case TaskStatus.InProgress:
            return "#EFF9F0";
        case TaskStatus.Completed:
            return "#138A36";
        case TaskStatus.Abandoned:
            return "#BA3F1D";
        default:
            return "#B0C0BC";
    }
}

interface IStatusControlProps {
    status: TaskStatus,
    setStatusCallback: (status: TaskStatus) => void,
}

export const StatusControl: React.FC<IStatusControlProps> = (props) => {
    const {status, setStatusCallback} = props;
    const [open, setOpen] = useState(false);
    const chipRef = useRef<HTMLDivElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    return <>
        <Chip className="taskStatusSelectorChip" icon={getStatusIcon(status)} label={status}
            onClick={handleOpen} size="small"
            sx={{ backgroundColor: getStatusColor(status)}} ref={chipRef}/>
        <Modal
            open={open}
            onClose={handleClose}
          >
            <Box sx={{
              position: "absolute",
              top: (chipRef.current?.getBoundingClientRect().top || 0) + (chipRef.current?.clientHeight || 0),
              left: chipRef.current?.getBoundingClientRect().left,
              width: 500,
              boxShadow: 24,
            }}>
                <div className="statusSelector">
                {
                    Object.keys(TaskStatus).map((status: string, index: number) =>
                        <Chip
                            key={index}
                            icon={getStatusIcon(status as TaskStatus)}
                            label={TaskStatus[status as keyof typeof TaskStatus]}
                            size="medium"
                            sx={{ backgroundColor: getStatusColor(status as TaskStatus) }}
                            onClick={() => setStatusCallback(TaskStatus[status as keyof typeof TaskStatus])}/>
                    )
                }
                </div>
            </Box>
      </Modal>
    </>;
}

export default StatusControl;
