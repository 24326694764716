import React, { useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { IProgressBadgeProps, ProgressBadge } from '../../assets/CalendarBadge';

import { Task } from "../Task";
import { TaskEvent, TaskEventEditor } from "../TaskEvent";
import { compareDates, dayOfWeekString, monthDays, monthStart } from "../utility/Dates";
import { EventLog } from "./EventLog";

import ColorPicker from "../utility/ColorPicker";

import "../../styles/App.css";
import "../../styles/Calendar.css";
import '../../styles/Dashboard.css';

interface ICalendarMonth {
    tasks: Task[],
    year: number,
    month: number
}

export const CalendarMonth: React.FC<ICalendarMonth> = (props: ICalendarMonth) => {
    const { tasks, year, month } = props;

    const weeks: ICalendarWeek[] = [];

    const referenceDate = new Date(year, month, 1);

    while (weeks.length === 0 || weeks[weeks.length - 1].startDate + 7 <= monthDays(referenceDate)) {
        const startDate = weeks.length === 0 ? 1 : weeks[weeks.length - 1].startDate - weeks[weeks.length - 1].offset + 7;
        if (weeks.length === 0) {
            weeks.push({
                offset: monthStart(referenceDate),
                startDate: startDate,
                ...props });
        }
        else if (startDate + 6 > monthDays(referenceDate)) {
            weeks.push({
                offset: 0,
                startDate: startDate,
                ...props});
        }
        else {
            weeks.push({
                offset: 0,
                startDate: startDate,
                ...props });
        }
    }

    return <>
        <div style={{ border: "1px solid black" }}>
            {
                weeks.map( (week: ICalendarWeek, index: number) =>
                    <CalendarWeek
                        tasks={tasks}
                        year={year}
                        month={month}
                        offset={week.offset}
                        startDate={week.startDate}
                        key={index} />)
            }
        </div>
    </>;
}

interface ICalendarWeek extends ICalendarMonth {
    offset: number,
    startDate: number,
}

export const CalendarWeek: React.FC<ICalendarWeek> = (props: ICalendarWeek) => {
    const { offset, startDate, year, month, tasks } = props;

    const referenceDate = new Date(year, month);
    const endDate = Math.min(startDate + 6 - offset, monthDays(referenceDate));
    
    const days: ICalendarDay[] = [];
    for (let i=0; i<offset; i++) {
        days.push({ ...props })
    }
    for (let i=startDate; i<=endDate; i++) {
        days.push({ day: i, label: i?.toString(), ...props })
    }
    for (let i=days.length; i<7; i++) {
        days.push({ ...props })
    }

    return <div className="calendarWeek">
        { days.map((date: ICalendarDay, index: number) =>
            <CalendarDay
                tasks={tasks}
                label={date.label}
                year={year}
                month={month}
                day={date.day}
                key={index} /> )}
    </div>
}

interface ICalendarDay extends ICalendarMonth {
    label?: string,
    day?: number,
}

export const CalendarDay: React.FC<ICalendarDay> = (props: ICalendarDay) => {
    const { year, month, day, label, tasks } = props;
    const [openDayModal, setOpenDayModal] = useState(false);

    const calendarDayRef = useRef<HTMLDivElement>(null);
    const handleOpenDayModal = (e: React.MouseEvent) => {
        if (!day) { return; }
        if ((e.target as Element).closest(".calendarDayContainer") === null
             || (e.target as Element).closest(".calendarDayModal") !== null){
            return;
        }
        setOpenDayModal(true);
    }
    const handleCloseDayModal = () => setOpenDayModal(false);

    const eventsByTask = new Map<Task, TaskEvent[]>();;
    for (var task of tasks) {
        eventsByTask.set(task,
            task.history.filter((event: TaskEvent) =>
                event.date.getFullYear() === year && event.date.getMonth() === month && event.date.getDate() === day)
        );
    }

    const dateData = new Date(year, month, day);
    const dateCompare = compareDates(dateData, new Date());
    let relativeDateClass;
    switch (dateCompare) {
        case -1:
            relativeDateClass = "calendarDayPast";
            break;
        case 1:
            relativeDateClass = "calendarDayFuture";
            break;
        case 0:
            relativeDateClass = "calendarDayToday";
            break
    }
    if (!day) { relativeDateClass = "calendarDayEmpty"; }

    return <div className={`calendarDayContainer ${relativeDateClass}`}>
        <div className="calendarDayContent" ref={calendarDayRef} onClick={handleOpenDayModal}>
            <div className="calendarDayHeader">
                <span className='calendarDayWeekdayLabel'>{ dayOfWeekString(dateData) }</span>
                <span className='calendarDayDayLabel'>{label}</span>
            </div>
            {
                Array.from(eventsByTask.keys()).map(
                    (task: Task, index: number) =>
                        (eventsByTask.get(task)?.length || 0) === 0 ? null : 
                            <CalendarProgressBadge key={index} 
                                events={eventsByTask.get(task)}
                                success={eventsByTask.get(task)?.filter((taskEvent: TaskEvent) => taskEvent.completed).length  || 0} 
                                total={eventsByTask.get(task)?.length || 1}
                                label={task.title} textColor={ColorPicker.getNeutralColor(task)} color={ColorPicker.getColor(task)}/>
                )
            }
        </div>
        <Modal className='calendarDayModal' open={openDayModal} onClose={handleCloseDayModal}>
            <Box className="modalBox" sx={{ 
                maxHeight: '75%',
                bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 600, overflow: "auto" }}>
              <Typography variant='h6'>{ dateData.toLocaleDateString(
                        'en-US', { month: 'long', day: 'numeric', year: 'numeric'}
                    )}</Typography>
              <EventLog
                logStart={new Date(year, month, day)}
                logEnd={new Date(year, month, day)}
                eventsByTask={eventsByTask}/>
            </Box>
        </Modal>
    </div>;
}

interface ICalendarProgressBadgeProps extends IProgressBadgeProps{
    events?: TaskEvent[]
}

export const CalendarProgressBadge: React.FC<ICalendarProgressBadgeProps> = (props: ICalendarProgressBadgeProps) => {
    const { events } = props;

    const [open, setOpen] = useState(false);
    const handleOpen = (e: React.MouseEvent) => {
        setOpen(true);
        e.stopPropagation();
        e.preventDefault();
    }
    const handleClose = () => setOpen(false);

    const isSingleEvent = events && events.length === 1;

    return <>  
            <div onClick={isSingleEvent ? handleOpen : undefined}>
                <ProgressBadge {...props}/>
            </div>
            {isSingleEvent && <TaskEventEditor taskEvent={events[0]} open={open} handleClose={handleClose} />}
        </>;
}
